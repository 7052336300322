import React, { useState } from 'react';
import config from '../../config';

const RegisterPage = () => {

    const[FirstName, setfirstName]=useState('');
    const[LastName, setlastName]=useState('');
    const[Email, setEmail]=useState('');
    const[Phone, setPhone]=useState('');
    const[Password, setPassword]=useState('');
    const[Address1, setAddress1]=useState('');
    const[Address2, setAddress2]=useState('');
    const[City, setCity]=useState('');
    const[State, setState]=useState('');
    const[ZipCode, setZipCode]=useState('');

    
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    
console.log(FirstName+LastName+Email+Phone+Password+Address1+Address2+City+State+ZipCode);

    formData.append('FirstName',FirstName);
    formData.append('LastName',LastName);
    formData.append('Email',Email);
    formData.append('Phone',Phone);
    formData.append('Password',Password);
    formData.append('Address1',Address1);
    formData.append('Address2',Address2);
    formData.append('City',City);
    formData.append('State',State);
    formData.append('ZipCode',ZipCode);

    console.log(formData);
    try {
      const response = await fetch(`${config.API_BASE_URL}/adduser`, {
        
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // Clear the form fields after submission
      setfirstName('');
      setlastName('');
      setEmail('');
      setPhone('');
      setPassword('');
      setAddress1('');
      setAddress2('');
      setCity('');
      setState('');
      setZipCode('');
     
      alert('User created successfully');
    } catch (error) {
      console.error('Error:', error);
      alert('Error creating user:'+error);
    }
  };

    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-100">
            <form className="bg-white p-10 rounded-lg shadow-lg max-w-lg w-full" onSubmit={handleSubmit}>
                <h2 className="text-2xl font-bold mb-5 text-gray-800">Registration</h2>
                <div className="grid grid-cols-2 gap-4">
                    <div className="flex flex-col">
                        <label className="mb-1 text-gray-600" htmlFor="first-name">First Name</label>
                        <input type="text" id="first-name" className="p-2 border rounded-lg" placeholder="John"  value={FirstName}
                        onChange={(e) => setfirstName(e.target.value)} />
                    </div>
                    <div className="flex flex-col">
                        <label className="mb-1 text-gray-600" htmlFor="last-name">Last Name</label>
                        <input type="text" id="last-name" className="p-2 border rounded-lg" placeholder="Doe" 
                        value={LastName} 
                        onChange={(e) => setlastName(e.target.value)}/>
                    </div>
                    <div className="flex flex-col">
                        <label className="mb-1 text-gray-600" htmlFor="email">E-mail</label>
                        <input type="email" id="email" className="p-2 border rounded-lg" placeholder="example@email.com" value={Email}
                        onChange={(e) => setEmail(e.target.value)}/>
                    </div>
                    <div className="flex flex-col">
                        <label className="mb-1 text-gray-600" htmlFor="mobile">Mobile No</label>
                        <input type="tel" id="mobile" className="p-2 border rounded-lg" placeholder="+123 456 789"
                        value={Phone} onChange={(e) => setPhone(e.target.value)} />
                    </div>
                    <div className="flex flex-col">
                        <label className="mb-1 text-gray-600" htmlFor="password">Password</label>
                        <input type="password" id="password" className="p-2 border rounded-lg" placeholder="Enter your password" 
                        value={Password} onChange={(e) => setPassword(e.target.value)}/>
                    </div>
                    <div className="flex flex-col">
                        <label className="mb-1 text-gray-600" htmlFor="address1">Address Line 1</label>
                        <input type="text" id="address1" className="p-2 border rounded-lg" placeholder="123 Street" 
                        value={Address1} onChange={(e) => setAddress1(e.target.value)}/>
                    </div>
                    <div className="flex flex-col">
                        <label className="mb-1 text-gray-600" htmlFor="address2">Address Line 2</label>
                        <input type="text" id="address2" className="p-2 border rounded-lg" placeholder="123 Street" 
                        value={Address2} onChange={(e) => setAddress2(e.target.value)}/>
                    </div>
                    <div className="flex flex-col">
                        <label className="mb-1 text-gray-600" htmlFor="city">City</label>
                        <input type="text" id="city" className="p-2 border rounded-lg" placeholder="New York" 
                        value={City} onChange={(e) => setCity(e.target.value)}/>
                    </div>
                    <div className="flex flex-col">
                        <label className="mb-1 text-gray-600" htmlFor="state">State</label>
                        <input type="text" id="state" className="p-2 border rounded-lg" placeholder="New York" 
                        value={State} onChange={(e) => setState(e.target.value)}/>
                    </div>
                    <div className="flex flex-col">
                        <label className="mb-1 text-gray-600" htmlFor="zip">ZIP Code</label>
                        <input type="text" id="zip" className="p-2 border rounded-lg" placeholder="123" 
                        value={ZipCode} onChange={(e) => setZipCode(e.target.value)}/>
                    </div>
                </div>
                <button className="mt-5 w-full bg-red-500 text-white p-2 rounded-lg">Create Account</button>
            </form>
        </div>
    );
};

export default RegisterPage;
