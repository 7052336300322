// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Components/Header/Header';
import Footer from './Components/Footer'; // Ensure Footer is within Router if needed
import ContactPage from './Components/Header/Contact';
import RegisterPage from './Components/Header/Register';
import HomePage from './Components/HomePage';
import Shop from './Components/Header/Shop';
import SingleProduct from './Components/Header/SingleProduct';
import SocialMedia from './Components/SocialMedia';
import CheckOut from './Components/CheckOut';
import PrivacyPolicy from './Components/Footer/PrivacyPolicy';
import Blog from './Components/Header/Blog';
import Aboutus from './Components/Header/Aboutus';
import TestimonialsVideos from './Components/Header/TestimonialsVideos';
import './App.css';
import Login from './Components/Login';
import { AuthProvider } from './AuthContext'; // Import AuthProvider
import TermsConditions from './Components/Footer/TermsConditions';

function App() {
  return (
   
      <Router>
        <SocialMedia />
        <Header />
        <Routes>
          <Route path='/login' element={<Login />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/create-account" element={<RegisterPage />} />
          <Route path='/shop' element={<Shop />} />
          <Route path='/checkout' element={<CheckOut />} />
          <Route path='/singleproduct/:productId' element={<SingleProduct />} />

          <Route path='/blog' element={<Blog />} />
          <Route path='/Aboutus' element={<Aboutus />} />
          <Route path='/TestimonialsVideos' element={<TestimonialsVideos />} />
          <Route path='/TermsConditions' element={<TermsConditions />} />
          <Route path='/PrivacyPolicy' element={<PrivacyPolicy />} />
        </Routes>
        <Footer />
          
          
      </Router>
  );
}

export default App;
