import React from 'react';


const ContactPage = () => {
  return (
    <div className="bg-gray-50">
      {/* Header */}
      <header className="text-center py-16">
        <h1 className="text-4xl font-bold text-gray-800">Contact us</h1>
        <p className="text-lg text-gray-600 mt-4">Start the conversation to establish a good relationship and business</p>
      </header>                               

      {/* Main Content */}
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-20">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          {/* Contact Information */}
          <div className="bg-white p-8 rounded-lg shadow-md">
            <h2 className="text-2xl font-semibold text-gray-800">We will be in touch shortly</h2>
            <p className="mt-4 text-gray-600">
                We appreciate your patience and will be in touch shortly. Thank you for your understanding!
            </p>
            <div className="mt-8 space-y-4">
             
              <div className="flex items-start space-x-4">
                <svg className="h-6 w-6 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 12l-4 4m0 0l-4-4m4 4V8" />
                </svg>
                <div>
                  <h3 className="text-lg font-medium text-gray-800">Email us</h3>
                  <p className="text-gray-600">nirogidhara@gmail.com</p>
                 
                </div>
              </div>
              <div className="flex items-start space-x-4">
                <svg className="h-6 w-6 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 9h12M8 13h12M8 17h12" />
                </svg>
                <div>
                  <h3 className="text-lg font-medium text-gray-800">Call us</h3>
                  <p className="text-gray-600"><a href="tel:+91-8094187941">+91-8094187941</a></p>
                  
                </div>
              </div>
            </div>
          </div>

          {/* Contact Form */}
          <div className="bg-white p-8 rounded-lg shadow-md">
            <h2 className="text-2xl font-semibold text-gray-800">Have other questions?</h2>
            <p className="mt-4 text-gray-600">
            Are you looking for information on a specific topic, or do you need help with something else? Let me know!
            </p>
            <form className="mt-8 space-y-6">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <input type="text" placeholder="Name" required className="w-full p-4 border rounded-lg  focus:ring-green-200" />
                <input type="tel" placeholder="Phone" required className="w-full p-4 border rounded-lg  focus:ring-green-200" />
              </div>
              <input type="email" placeholder="Email" required className="w-full p-4 border rounded-lg focus:ring-green-200" />
              <input type="text" placeholder="Subject" required className="w-full p-4 border rounded-lg focus:ring-green-200" />
              <textarea placeholder="Message" required className="w-full p-4 border rounded-lg focus:ring-green-200"></textarea>
              <button type="submit" className="w-full py-4 bg-green-600 text-white font-semibold rounded-lg hover:bg-green-700 focus:ring-green-200">
                Send Message
              </button>
            </form>
          </div>
        </div>

        {/* Map */}
        <div className="mt-12">
          <iframe
            className="w-full h-96 rounded-lg shadow-md"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3458.4338670845527!2d73.86231297500521!3d29.909409224957017!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3917b540988a2939%3A0x814849330b997dbc!2sBasanti%20Chowk%2C%20Hardeep%20Colony%2C%20Sri%20Ganganagar%2C%203%20A%2C%20Rajasthan%20335001!5e0!3m2!1sen!2sin!4v1726745235471!5m2!1sen!2sin"
            allowFullScreen=""
            loading="eager"
          ></iframe>
        </div>
      </main>

      {/* Footer */}
      <footer className="bg-gray-100 py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          
          <div className="mt-2 text-center">
            <p className="text-gray-600">Signup our newsletter to get updated information, news, insight or promotions.</p>
            <form className="mt-6 flex justify-center">
              <input
                type="email"
                placeholder="Enter your email"
                className="p-4 border rounded-lg focus:ring focus:ring-green-200"
              />
              <button className="ml-2 py-4 px-6 bg-green-600 text-white font-semibold rounded-lg hover:bg-green-700 focus:ring focus:ring-green-200">
                Sign up
              </button>
            </form>
          </div>
        </div>
      </footer>
    </div>
  );
};
export default ContactPage;
