import React from 'react';

const ServicesSection = () => {
  const services = [
    { title: 'Consultations', description: 'Expert guidance tailored to your needs, ensuring personalized solutions for every challenge' },
    { title: 'Treatments', description: 'Comprehensive and specialized treatments designed to promote healing and well-being' },
    { title: 'Ayurvedic Store', description: 'Discover a range of authentic Ayurvedic products crafted to enhance natural wellness and balance' },
  ];

  return (
    <section className="bg-gray-50 py-16 px-10">
      <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {services.map((service, index) => (
          <div key={index} className="p-6 bg-white rounded-lg shadow hover:shadow-lg transition-shadow duration-300">
            <h3 className="text-xl font-semibold text-gray-800">{service.title}</h3>
            <p className="mt-4 text-gray-600">{service.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ServicesSection;
