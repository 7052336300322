import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { FaRegCirclePlay } from 'react-icons/fa6';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'tailwindcss/tailwind.css';
import config from '../../config';

const TestimonialsVideos = () => {
  const [playingVideoIndex, setPlayingVideoIndex] = useState(null);
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const response = await fetch(`${config.API_BASE_URL}/fetchtestimonials`);
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
        const data = await response.json();
        const formattedTestimonials = data.map((testimonial, index) => ({
          id: index,
          name: testimonial.name,
          role: testimonial.occupation,
          image: testimonial.profileImage,
          testimonial: testimonial.testimonialMessage,
          videoUrl: testimonial.videoLink || '',  // Only use videoLink if provided
        }));
        setTestimonials(formattedTestimonials);
      } catch (error) {
        console.error('Error fetching testimonials:', error);
      }
    };
    fetchTestimonials();
  }, []);

  const sliderSettings = {
    dots: false,
    infinite: testimonials.length > 1,
    speed: 500,
    slidesToShow: Math.min(3, testimonials.length),
    slidesToScroll: 1,
    autoplay: testimonials.length > 1,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: Math.min(2, testimonials.length),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handlePlayVideo = (index) => {
    setPlayingVideoIndex(playingVideoIndex === index ? null : index);
  };

  return (
    <div className="py-16 px-4 lg:px-20 bg-gray-50">
      <h2 className="text-center text-3xl font-bold text-gray-700 mb-4">What they say about us</h2>
      <p className="text-center text-gray-500 mb-8">
        Our clients praise us for delivering exceptional care, personalized service, and transformative results that exceed their expectations.
      </p>


      {testimonials.length > 0 ? (
        <Slider {...sliderSettings} className="testimonials-slider">
          {testimonials.map((testimonial) => (
            <div key={testimonial.id} className="p-4 w-full">
              <div className="bg-white rounded-lg shadow-lg p-6 text-center mx-auto">
                <div className="flex flex-col items-center space-y-4">

                  {/* Show video thumbnail and play button if videoUrl is provided and not "No" */}
                  {testimonial.videoUrl && testimonial.videoUrl !== 'No' ? (
                    <div className="relative w-full">
                      {playingVideoIndex !== testimonial.id ? (
                        <>
                          <img
                            src={'https://as2.ftcdn.net/v2/jpg/03/93/85/55/1000_F_393855516_0BcgVZqNlVrvDE0kiD3YTlVJaur8Q02G.jpg'}
                            alt="Video thumbnail"
                            className="rounded-lg w-full h-40 object-cover"
                          />
                          <div
                            className="absolute inset-0 flex items-center justify-center cursor-pointer"
                            onClick={() => handlePlayVideo(testimonial.id)}
                          >
                            <div className="bg-green-600 p-2 rounded-full">
                              <FaRegCirclePlay className="text-white text-3xl" />
                            </div>
                          </div>
                        </>
                      ) : (
                        <iframe
                          className="w-full h-40 rounded-lg"
                          src={testimonial.videoUrl}
                          title="Testimonial Video"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                      )}
                    </div>
                  ) : (
                    // If no video URL or it's "No", show the profile image instead
                    <img
                      src={`${config.API_BASE_URL}/testimonialimages/${testimonial.image}`}
                      alt={testimonial.name}
                      className="w-40 h-40 object-cover"
                    />
                  )}

                  <p className="italic text-gray-500 text-sm">{testimonial.testimonial}</p>
                  <div className="flex items-center space-x-2 mt-4">
                    <img
                      src={testimonial.image === "NO PROVIDE"
                        ? 'https://randomuser.me/api/portraits/men/75.jpg'
                        : `${config.API_BASE_URL}/testimonialimages/${testimonial.image}`}
                      alt={testimonial.name}
                      className="w-10 h-10 rounded-full"
                    />
                    <div className="text-left">
                      <h3 className="font-semibold text-green-600 text-sm">{testimonial.name}</h3>
                      <p className="text-gray-500 text-xs">{testimonial.role}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      ) : (
        <p className="text-center text-gray-500">No testimonials available at this time.</p>
      )}
    </div>
  );
};

export default TestimonialsVideos;
