import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Cookies from 'js-cookie';
import { useState, useEffect } from 'react';
import config from '../config';
import { motion } from 'framer-motion';
import { FaMinusCircle } from "react-icons/fa";
import { FaPlusCircle } from "react-icons/fa";
import { Link, Navigate } from 'react-router-dom';

export default function Cart() {
    const [open, setOpen] = useState(true);
    const [cartProduct, setCartProduct] = useState([]);

    useEffect(() => {
        const useremail = Cookies.get('username');
        const usermobile = Cookies.get('mobile');
        const fetchCartProducts = async () => {
            try {
                const response = await fetch(`${config.API_BASE_URL}/cart/${useremail}/${usermobile}`);
                if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
                const data = await response.json();
                setCartProduct(data);
            } catch (error) {
                console.error('Error fetching cart products:', error);
            }
        };

        fetchCartProducts();
    }, []);

    const updateQuantity = async (cartid, newQty) => {
        const useremail = Cookies.get('username');
        const usermobile = Cookies.get('mobile');
    
        if (newQty < 1) return;
    
        try {
            const response = await fetch(`${config.API_BASE_URL}/update-cart`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    cartid,
                    useremail,
                    usermobile,
                    qty: newQty
                }),
            });
    
            if (!response.ok) throw new Error(`Failed to update quantity. Status: ${response.status}`);
    
            window.location.reload(); // Refresh the page
            setCartProduct(cartProduct.map(product =>
                product.id === cartid ? { ...product, qty: newQty } : product
            ));
    
        } catch (error) {
            console.error('Error updating cart quantity:', error);
        }
    };

    const removeItem = async (cartid) => {
        const useremail = Cookies.get('username');
        const usermobile = Cookies.get('mobile');

        try {
            const response = await fetch(`${config.API_BASE_URL}/delete-from-cart`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    cartid,
                    useremail,
                    usermobile
                }),
            });

            if (!response.ok) throw new Error(`Failed to remove item. Status: ${response.status}`);

            window.location.reload(); // Refresh the page
            setCartProduct(cartProduct.filter(product => product.id !== cartid));

        } catch (error) {
            console.error('Error removing cart item:', error);
        }
    };

    const calculateSubtotal = () => {
        return cartProduct.reduce((total, product) => {
            const price = parseFloat(product.price) || 0;
            const quantity = product.qty || 1;
            return total + price * quantity;
        }, 0);
    };

    return (
        <Dialog open={open} onClose={() => setOpen(false)} className="relative z-10">
            <DialogBackdrop className="fixed inset-0 bg-gray-500 bg-opacity-75" />

            <div className="fixed inset-0 overflow-hidden">
                <div className="absolute inset-0 overflow-hidden">
                    <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                        <DialogPanel className="pointer-events-auto w-screen max-w-md">
                            <motion.div
                                className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
                                initial={{ x: '100vw' }}
                                animate={{ x: 0 }}
                                transition={{ type: 'tween', stiffness: 300 }}
                            >
                                <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                                    <div className="flex items-start justify-between">
                                        <DialogTitle className="text-lg font-medium text-gray-900">Shopping cart</DialogTitle>
                                        <div className="ml-3 flex h-7 items-center">
                                            <button
                                                type="button"
                                                onClick={() => setOpen(false)}
                                                className="relative -m-2 p-2 text-gray-400 hover:text-gray-500"
                                            >
                                                <span className="sr-only">Close panel</span>
                                                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                                            </button>
                                        </div>
                                    </div>

                                    <div className="mt-8">
                                        <div className="flow-root">
                                            <ul role="list" className="-my-6 divide-y divide-gray-200">
                                                {cartProduct.map((product, index) => (
                                                    <motion.li
                                                        key={product.id}
                                                        className="flex py-6"
                                                        initial={{ opacity: 0, x: 30 }}
                                                        animate={{ opacity: 1, x: 0 }}
                                                        transition={{ delay: index * 0.1, duration: 0.9}}
                                                    >
                                                        <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                                                            <img
                                                                alt={product.productName}
                                                                src={`${config.API_BASE_URL}/images/${JSON.parse(product.images)[0]}`}
                                                                className="h-full w-full object-cover object-center"
                                                            />
                                                        </div>

                                                        <div className="ml-4 flex flex-1 flex-col">
                                                            <div>
                                                                <div className="flex justify-between text-base font-medium text-gray-900">
                                                                    <h3>{product.productName}</h3>
                                                                    <p className="ml-4">₹ {product.price}/-</p>
                                                                </div>
                                                                <p className="mt-1 text-sm text-gray-500">{product.color}</p>
                                                            </div>

                                                            <div className="flex flex-1 items-end justify-between text-sm">
                                                                <div className="flex items-center">
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => updateQuantity(product.id, product.qty - 1)}
                                                                        className="px-2  text-green-500 hover:text-green-700"
                                                                    >
                                                                        <FaMinusCircle />
                                                                    </button>
                                                                    <input
                                                                        type="number"
                                                                        value={product.qty}
                                                                        onChange={(e) => updateQuantity(product.id, parseInt(e.target.value))}
                                                                        className="mx-2 w-12 border border-green-500 text-center"
                                                                        min="1"
                                                                    />
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => updateQuantity(product.id, parseInt(product.qty) + 1)}
                                                                        className="px-2  text-green-500 hover:text-green-700"
                                                                    >
                                                                        <FaPlusCircle />
                                                                    </button>
                                                                </div>
                                                                <div className="flex">
                                                                    <motion.button
                                                                        type="button"
                                                                        onClick={() => removeItem(product.id)}
                                                                        className="font-medium text-red-600 hover:text-red-500"
                                                                        whileHover={{ scale: 1.1, rotate: -5 }}
                                                                        whileTap={{ scale: 0.9 }}
                                                                        transition={{ type: 'spring', stiffness: 300 }}
                                                                    >
                                                                        Remove
                                                                    </motion.button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </motion.li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
                                    <div className="flex justify-between text-base font-medium text-gray-900">
                                        <p>Subtotal</p>
                                        <p>₹ {calculateSubtotal()}</p>
                                    </div>
                                    <p className="mt-0.5 text-sm text-gray-500">Shipping and taxes calculated at checkout.</p>
                                    <div className="mt-6">
                                        <Link
                                            to="/checkout"
                                            onClick={() => setOpen(false)}
                                            className="flex items-center justify-center rounded-md border border-transparent bg-green-500 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-green-600"
                                        >
                                            Checkout
                                        </Link>
                                    </div>
                                    <div className="mt-6 flex justify-center text-center text-sm text-gray-500">
                                        <p>
                                            or{' '}
                                            <button
                                                type="button"
                                                onClick={() => setOpen(false)}
                                                className="font-medium text-green-600 hover:text-green-500"
                                            >
                                                Continue Shopping<span aria-hidden="true"> &rarr;</span>
                                            </button>
                                        </p>
                                    </div>
                                </div>
                            </motion.div>
                        </DialogPanel>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}
