import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for routing

const Footer = () => {
  // Function to handle form submissions
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission
    // Add any additional logic for form submission here
  };

  return (
    <div className="bg-gray-100 py-8 px-4">
      {/* Centered Container */}
      <div className="max-w-7xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
          {/* Contact Us Section */}
          <div className="bg-gray-100 text-white p-2 rounded-md ">
            <h3 className="bg-green-600 px-4 py-2 text-lg font-bold mb-4 rounded-md">Contact Us</h3>
            <form className="space-y-4" onSubmit={handleSubmit}>
              <input
                type="text"
                placeholder="Your Name"
                className="w-full p-2 rounded-md border text-black border-gray-300 focus:outline-none"
              />
              <input
                type="text"
                placeholder="Your Number"
                className="w-full p-2 rounded-md border text-black border-gray-300 focus:outline-none"
              />
              <input
                type="text"
                placeholder="City"
                className="w-full p-2 rounded-md border text-black border-gray-300 focus:outline-none"
              />
              <textarea
                placeholder="Your Message"
                className="w-full p-2 rounded-md border text-black border-gray-300 focus:outline-none h-32"
              />
              <button
                type="submit"
                className="bg-green-600 hover:bg-green-700 font-medium text-white w-full py-2 rounded-md"
              >
                SUBMIT
              </button>
              <img
                src="https://shuddhi.com/wp-content/uploads/2024/08/FSSAI_logo.png"
                alt="FSSAI Logo"
                className="pt-8 w-24"
              />
            </form>
          </div>

          {/* Ayurveda Section */}
          <div className="text-center">
            <img src="https://trademaklogos.s3.ap-south-1.amazonaws.com/5958309.jpeg" alt="Shuddhi Ayurveda" className="mx-auto mb-4" />
            <p className="text-gray-700">
              Stay connected with us and embrace a healthier lifestyle! At NirogiDhara, we are committed to providing you with the best Ayurvedic solutions and personalized care. For inquiries, feedback, or support, feel free to reach out through our contact page. Thank you for choosing us as your partner in wellness—let’s embark on this journey together!
            </p>
            <Link to="/Aboutus"> {/* Use Link for routing */}
              <button className="bg-green-600 hover:bg-green-700 font-medium text-white px-4 py-2 mt-4 rounded-md">
                About Us
              </button>
            </Link>
          </div>

          {/* Newsletter Subscribe Section */}
          <div className="bg-gray-100 text-white p-6 rounded-md ">
            <h3 className="bg-green-600 px-4 py-2 text-lg font-bold mb-8 rounded-md">Our Latest Offer</h3>
            <h2 className='text-black text-2xl text-center font-bold mb-2'>Get Latest Offer</h2>
            <form className="space-y-4" onSubmit={handleSubmit}>
              <input
                type="email"
                placeholder="Your Email"
                className="w-full p-2 rounded-md border border-gray-300 focus:outline-none"
              />
              <button
                type="submit"
                className="bg-green-600 hover:bg-green-700 font-medium text-white w-full py-2 rounded-md"
              >
                SUBMIT
              </button>
            </form>
          </div>
        </div>

        {/* Footer Links Section */}
        <div className="mt-2">
          <div className="border-t-2 pt-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8 pl-4 text-gray-600">
            {/* About Section */}
            <div className="text-left">
              <h3 className="font-bold text-lg">ABOUT</h3>
              <div className="border-b-4 w-10 border-green-600 mb-4 rounded-lg"></div>
              <ul className="space-y-3 text-base">
                <li><Link to="/aboutus" className="hover:text-green-700 hover:underline">About Us</Link></li>
                <li><Link to="/blog" className="hover:text-green-700 hover:underline">Blogs</Link></li>
                {/* <li><Link to="#" className="hover:text-green-700 hover:underline">Career</Link></li>
                <li><Link to="#" className="hover:text-green-700 hover:underline">Services</Link></li> */}
              </ul>
            </div>

            {/* Shop Section */}
            <div className="text-left">
              <h3 className="font-bold text-lg">SHOP</h3>
              <div className="border-b-4 w-10 border-green-600 mb-4 rounded-lg"></div>
              <ul className="space-y-3 text-base">
                <li><Link to="/shop" className="hover:text-green-700 hover:underline">Shop</Link></li>
                <li><Link to="/contact" className="hover:text-green-700 hover:underline">Contact Us</Link></li>
              </ul>
            </div>

            {/* Quick Links Section */}
            <div className="text-left">
              <h3 className="font-bold text-lg">QUICK LINKS</h3>
              <div className="border-b-4 w-10 border-green-600 mb-4 rounded-lg"></div>
              <ul className="space-y-3 text-base">
                {/* <li><Link to="#" className="hover:text-green-700 hover:underline">Camps</Link></li> */}
                <li><Link to="/termsconditions" className="hover:text-green-700 hover:underline">Terms And Conditions</Link></li>
                <li><Link to="/privacypolicy" className="hover:text-green-700 hover:underline">Privacy Policy</Link></li>
              </ul>
            </div>

            {/* Complaints/Grievances Section */}
            <div className="text-left">
              <h3 className="font-bold text-lg">CONTACT</h3>
              <div className="border-b-4 w-10 border-green-600 mb-4 rounded-lg"></div>
              <ul className="space-y-3 text-base">
                <li><Link to="/contact" className="hover:text-green-700 hover:underline">Customer Support</Link></li>
                <li><Link to="/contact" className="hover:text-green-700 hover:underline">FAQs</Link></li>
                {/* Additional complaint links can be uncommented if needed */}
                {/* <li><Link to="#" className="hover:text-green-700 hover:underline">Employee Grievance</Link></li>
                <li><Link to="#" className="hover:text-green-700 hover:underline">Customer Grievance</Link></li>
                <li><Link to="#" className="hover:text-green-700 hover:underline">Posh Complaints</Link></li>
                <li><Link to="#" className="hover:text-green-700 hover:underline">Whistle Blower</Link></li> */}
              </ul>
            </div>
          </div>

          <div className="border-t-2 text-gray-600 mt-4"></div>
          <div className="mt-4 text-center text-gray-600">
            <p>© 2024 Nirogi Dhara, All Rights Reserved.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
