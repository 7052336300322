import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../config';
import Cookies from 'js-cookie';

const ProductsSection = () => {
    const navigate = useNavigate();
    const [products, setProducts] = useState([]);
    const [cartProduct, setCartProduct] = useState([]);
    const [visibleProducts, setVisibleProducts] = useState([]); // Tracks visible products
    const productRefs = useRef([]); // Refs to track product elements

    const productview = (productId) => {
      
        // alert("Product View");
        navigate(`/singleproduct/${productId}`);
    }

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await fetch(`${config.API_BASE_URL}/fetchproducts`);
                if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
                const data = await response.json();
                setProducts(data);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchProducts();
    }, []);

    useEffect(() => {
        const observerOptions = {
            threshold: 0.1, // Trigger when 10% of the element is visible
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const index = parseInt(entry.target.getAttribute('data-index'), 10);
                    setVisibleProducts((prevVisibleProducts) => [...prevVisibleProducts, index]);
                    observer.unobserve(entry.target); // Unobserve once it's visible
                }
            });
        }, observerOptions);

        productRefs.current.forEach((ref) => {
            if (ref) observer.observe(ref);
        });

        return () => {
            productRefs.current.forEach((ref) => {
                if (ref) observer.unobserve(ref);
            });
        };
    }, [products]);


    const truncateDescription = (description, wordLimit) => {
        return description.split(' ').slice(0, wordLimit).join(' ') + (description.split(' ').length > wordLimit ? '...' : '');
    };

    

    return (
        <section className="bg-white">
            <div className="container px-20">
                <h1 className="text-3xl text-center font-bold text-green-800">Our Recent Top Selling Products</h1>
                <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-4 text-center mt-4 mb-4">
                    {products.map((product, index) => (
                        <div onClick={() => productview(product.id)}
                            key={index}
                            data-index={index}
                            ref={(el) => (productRefs.current[index] = el)} // Attach ref to each product card
                            className={`p-5 bg-white rounded-lg shadow transition-all duration-1000 ease-out 
                            ${visibleProducts.includes(index) ? 'transform translate-x-0 opacity-100' : 'transform translate-x-20 opacity-0'}
                            hover:border border-gray-400`}
                            style={{ transitionDelay: `${index * 100}ms` }} // Stagger animation based on index
                        >
                            {product.images && product.images.length > 0 ? (
                                <img
                                    src={`${config.API_BASE_URL}/images/${JSON.parse(product.images)[0]}`}
                                    alt="Product Image 0"
                                    className="w-full h-50 object-cover rounded-lg mb-2"
                                />
                            ) : (
                                <p>No image available</p>
                            )}
                            <h3 className="text-lg font-semibold text-gray-800">{product.productName}</h3>
                            <p className="mt-3 text-gray-600">{truncateDescription(product.description, 25)}</p>
                            <div className="flex items-center mb-2">
                                <p className="text-red-600 text-3xl line-through mr-4">₹ {product.overprice}/-</p>
                                <p className="text-green-600 text-3xl ml-4">₹ {product.price}/-</p>
                            </div>
                            <button onClick={() => productview(product.id)} className="mt-4 mx-4 bg-green-600 text-white py-2 px-4 rounded-full hover:bg-green-700 transition duration-300">
                                View Product
                            </button>
                          
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default ProductsSection;
