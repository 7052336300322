// src/components/DiseaseFormModal.js
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)', // Semi-transparent background
    zIndex: 9999, // High z-index to overlay on other content
  },
  content: {
    top: '35%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: '500px',
    zIndex: 10000, 
  },
};

const DiseaseFormModal = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    disease: '',
  });

  useEffect(() => {
    if (isOpen) {
      // Reset form when modal opens
      setFormData({ name: '', email: '', phone: '', disease: '' });
    }
  }, [isOpen]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission (e.g., save to local storage, send to API)
    console.log(formData);
    onClose(); // Close modal after submission
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={customStyles}
      contentLabel="Disease Form Modal"
    >
      <h2 className="text-xl font-bold mb-4 text-center">Connect with Us</h2>
      <button onClick={onClose} className="absolute top-2 right-2 text-xl font-bold hover:text-red-600">X</button>
      <form onSubmit={handleSubmit} className="space-y-4">
        <input
          type="text"
          name="name"
          placeholder="Your Name"
          value={formData.name}
          onChange={handleChange}
          required
          className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-green-500"
        />
        <input
          type="email"
          name="email"
          placeholder="Your Email"
          value={formData.email}
          onChange={handleChange}
          required
          className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-green-500"
        />
        <input
          type="text"
          name="phone"
          placeholder="Your Phone"
          value={formData.phone}
          onChange={handleChange}
          required
          className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-green-500"
        />
        <select
          name="disease"
          value={formData.disease}
          onChange={handleChange}
          required
          className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-green-500"
        >
          <option value="">Select Disease</option>
          <option value="Heart">Heart</option>
          <option value="Liver">Liver</option>
          <option value="Kidney">Kidney</option>
        </select>
        <button
          type="submit"
          className="w-full p-2 bg-green-700 text-white rounded hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-green-500"
        >
          Submit
        </button>
      </form>
    </Modal>
  );
};

export default DiseaseFormModal;
