import React from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom';

import slide1 from './slide1.jpg';
import slide2 from './slide2.png';
import slide3 from './slide3.png';


const HeroSection = () => {
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };

  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/Aboutus');
  };

  return (
    <section className="relative bg-gray-50 py-10 px-4 md:px-8 lg:px-20 overflow-hidden">
      {/* Animated background */}
      <div className="absolute inset-0 bg-green-100 rounded-full w-64 h-64 md:w-96 md:h-96 lg:w-[500px] lg:h-[500px] opacity-30 -z-10 lg:left-40"></div>

      <div className="container mx-auto flex flex-col lg:flex-row items-center relative z-10">
        {/* Text Section */}
        <div className="w-full lg:w-1/2 text-center lg:text-left mb-10 lg:mb-0">
          <h4 className="text-green-600 italic font-medium">Welcome to NirogiDhara</h4>
          <h1 className="text-2xl md:text-4xl font-bold text-gray-800">
            NATURAL CARE FOR GOOD HEALTH
          </h1>
          <p className="text-gray-600 mt-4">
            NirogiDhara, an Ayurvedic Organization Founded by Mr. Prarit Sidana with the mission to help people in enhancing their health conditions naturally without any side effects through the various approaches of Ayurveda around the world.
          </p>
          <button
            onClick={handleButtonClick}
            className="mt-6 bg-green-600 text-white py-2 px-4 rounded-full hover:bg-green-700 transition duration-300"
          >
            Discover more
          </button>
        </div>

        {/* Slider Section */}
        <div className="w-full lg:w-1/2 mt-10 lg:mt-0 flex justify-center">
          <Slider {...sliderSettings} className="w-full md:w-3/4">
            <div>
              <img src={slide1} alt="Herbal Supplement 1" className="w-full object-contain" />
            </div>
            <div>
              <img src={slide2} alt="Herbal Supplement 2" className="w-full object-contain" />
            </div>
            <div>
              <img src={slide3} alt="Herbal Supplement 3" className="w-full object-contain" />
            </div>
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
