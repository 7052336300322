import React from 'react';
import { FaFacebookF } from "react-icons/fa";
import { SlSocialInstagram } from "react-icons/sl";
import { FiYoutube } from "react-icons/fi";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaWhatsapp } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";



const SocialMedia = () => {
    return (
        <div>
            <div className="fixed bottom-4 right-4 flex flex-col space-y-2 z-50">
                {/* Facebook */}
                <a
                    href="https://www.facebook.com/NirogiDhara"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-blue-600 p-2 rounded-full  text-white transition duration-300"
                >
                    <FaFacebookF />

                </a>

                {/* Instagram */}
                <a
                    href="https://www.instagram.com/nirogidhara"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-pink-600 p-2 rounded-full  text-white  transition duration-300"
                >
                    <SlSocialInstagram />

                </a>

                {/* LinkedIn */}
                <a
                    href="https://www.linkedin.com/in/Nirogidhara"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-blue-700 p-2  rounded-full  text-white transition duration-300"
                >
                    <FaLinkedinIn />

                </a>

                {/* YouTube */}
                <a
                    href="https://www.youtube.com/channel/NirogiDhara"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-red-600 p-2 rounded-full  text-white transition duration-300"
                >
                    <FiYoutube />

                </a>

        
                <a
                    href="https://www.whatsapp.com/channel/yourchannel"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-green-400 p-2 rounded-full  text-white transition duration-300"
                >
                    <FaWhatsapp />

                </a>
         
                <a
                    href="https://www.telegram.com/channel/yourchannel"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-blue-400 text-white p-2 rounded-full transition duration-300"
                >
                    <FaTelegramPlane />

                </a>

                <a
                    href="https://www.telegram.com/channel/yourchannel"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-blue-400 text-white p-2 rounded-full transition duration-300"
                >
                    <FaTwitter />

                </a>


            </div>
        </div>
    );
}
export default SocialMedia;