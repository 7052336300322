import React, { useState } from 'react';
import { FaCheckCircle } from "react-icons/fa";
import { FaRegCirclePlay } from "react-icons/fa6";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ServiceSteps from '../ServicesSteps';
import NewsletterSection from '../Newsletter';
import TestimonialsSection from '../Testimonials';
import { Link } from 'react-router-dom';

const Aboutus = () => {
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);

    const handlePlayVideo = () => {
        setIsVideoPlaying(true);
    };

    const sliderSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,  // Show 5 logos by default
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,

    };
    console.log(typeof "Hello World");

    return (
        <>
            {/* Hero Section */}
            <div className="bg-gray-50 p-10 flex flex-col md:flex-row items-center justify-between px-6 md:px-20 mt-4">
                {/* Image/Video with Play Button Overlay */}
                <div className="relative w-full md:w-1/2 mb-6 md:mb-0 px-4 md:px-16">
                    {!isVideoPlaying ? (
                        <div className="relative">
                            <img
                                src="https://fullkit.moxcreative.com/bagasaka/wp-content/uploads/sites/9/2022/07/kizhi-or-herbal-bolus-bags-ayurveda-massage.jpg"
                                alt="Experience"
                                className="w-full rounded-lg shadow-lg"
                            />
                            {/* Play Button Overlay */}
                            <div
                                className="absolute inset-0 flex items-center justify-center cursor-pointer"
                                onClick={handlePlayVideo}
                            >
                                <div className="bg-green-600 text-white p-4 rounded-full hover:bg-green-700 transition">
                                    <FaRegCirclePlay className="text-5xl text-white" />
                                </div>
                            </div>
                            <div className="absolute top-5 left-5 bg-green-600 text-white p-4 sm:p-2 sm:top-2 sm:left-2 rounded-full text-center">
                                <span className="text-2xl sm:text-xl font-bold">25+</span>
                                <p className="text-xs">Years Experience</p>
                            </div>

                        </div>
                    ) : (
                        // Video Embed
                        <iframe
                            className="w-full h-64 md:h-96 rounded-lg shadow-lg"
                            src="https://www.youtube.com/embed/dQw4w9WgXcQ?autoplay=1"
                            title="Experience Video"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    )}
                </div>

                {/* Text Content */}
                <div className="md:w-1/2 text-center md:text-left p-5">
                    <h5 className="italic font-semibold text-green-600">Who we are</h5>
                    <h2 className="text-3xl font-bold text-gray-700 mb-4">The combination of nature and science.</h2>
                    <p className="text-gray-500 mb-6">
                        By blending the wisdom of natural remedies with the precision of modern scientific research, we can create treatments that are both effective and gentle. It ensures long-lasting results while nurturing the body in a holistic way.
                    </p>
                    <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>

                    <ul className="text-gray-700 space-y-2">
                        <li className="font-semibold text-green-600">
                            <FaCheckCircle className="inline mr-2 text-green-600" />
                            100% Natural Products
                        </li>
                        <li className="font-semibold text-green-600">
                            <FaCheckCircle className="inline mr-2 text-green-600" />
                            Organic Herbal
                        </li>
                        <li className="font-semibold text-green-600">
                            <FaCheckCircle className="inline mr-2 text-green-600" />
                            24/7 Support
                        </li>
                    </ul>
                </div>
            </div>

            {/* Featured Brand Section */}
            

            {/* Value Section */}
            <div className="relative bg-cover bg-center  p-52 bg-[url('https://www.navayurherbals.com/wp-content/uploads/2023/04/navayur-blog-768x486.webp')]">
                <div className="absolute inset-0 bg-black opacity-50"></div>
                <div className="relative text-center text-white">
                    <h2 className="text-4xl font-bold mb-4">Treat your body with the care of nature</h2>
                    <p className="mb-8">Our remedies bring the extraordinary healing power of nature to everyday people, making wellness accessible and effective for all.</p>
                    {/* <Link
                        to="/Aboutus"
                        className="bg-green-600 hover:bg-green-700 transition py-2 px-6 rounded-full"
                    >
                        Discover More
                    </Link> */}
                </div>
            </div>

            <ServiceSteps />
            <TestimonialsSection />
            <NewsletterSection />
        </>
    );
};

export default Aboutus;
