import React from 'react';

const NewsletterSection = () => {
  return (
    <section className="py-12 px-10 bg-green-600 text-white">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold">Subscribe to our Newsletter</h2>
        <p className="mt-4">Get the latest updates and offers.</p>
        <form className="mt-8 flex justify-center">
          <input
            type="email"
            placeholder="Enter your email"
            className="p-2 rounded-l-lg text-gray-800"
          />
          <button className="bg-white text-green-600 py-2 px-4 rounded-r-lg hover:bg-gray-200 transition duration-300">
            Subscribe
          </button>
        </form>
      </div>
    </section>
  );
};

export default NewsletterSection;
