import React, { useState } from 'react';
import config from '../../config'; // Make sure you have your config for API

const InquiryForm = ({ products }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        contact: '',
        inquiry: ''
    });

    const [errors, setErrors] = useState({});
    const [alert, setAlert] = useState(null); // Alert for success or error message

    const validateForm = () => {
        let tempErrors = {};
        const nameRegex = /^[a-zA-Z\s]*$/;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const contactRegex = /^[0-9]{10}$/;

        // Validate Name (only a-z characters)
        if (!formData.name || !nameRegex.test(formData.name)) {
            tempErrors.name = 'Name should only contain letters (a-z or A-Z)';
        }

        // Validate Email (valid email format)
        if (formData.email && !emailRegex.test(formData.email)) {
            tempErrors.email = 'Please enter a valid email (example@domain.com)';
        }

        // Validate Contact Number (must be 10 digits)
        if (formData.contact && !contactRegex.test(formData.contact)) {
            tempErrors.contact = 'Contact number should be 10 digits long';
        }

        // Check if at least one of Email or Contact is provided
        if (!formData.email && !formData.contact) {
            tempErrors.contact = 'Please provide either email or contact number';
        }

        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0; // Return true if no errors
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // Prevent non-digit characters in the contact number field and restrict length
        if (name === 'contact' && (value.length > 10 || !/^\d*$/.test(value))) {
            return;
        }

        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            return;
        }

        try {
            const response = await fetch(`${config.API_BASE_URL}/inquiry`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    products, // Send the entire products object
                    ...formData
                })
            });

            if (!response.ok) {
                throw new Error('Failed to submit inquiry');
            }

            // Show success alert and reset form data
            setAlert({ type: 'success', message: 'Inquiry submitted successfully!' });
            setFormData({
                name: '',
                email: '',
                contact: '',
                inquiry: ''
            });
        } catch (error) {
            console.error('Error submitting inquiry:', error);

            // Show error alert
            setAlert({
                type: 'error',
                message: 'Due to some internal errors your inquiry is not submitted, please contact our customer support team.'
            });
        }
    };

    return (
        <div className="bg-white rounded-lg">
            <h2 className="text-2xl font-semibold mb-4">Inquiry about This Product</h2>

            {/* Alert Messages */}
            {alert && (
                <div className={`mb-4 p-4 rounded-lg ${alert.type === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
                    {alert.message}
                </div>
            )}

            <form onSubmit={handleSubmit}>
                {/* Name Field */}
                <div className="mb-4">
                    <label className="block text-gray-700 mb-2" htmlFor="name">
                        Your Name:
                    </label>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg"
                        placeholder="Enter your name"
                    />
                    {errors.name && (
                        <p className="text-red-600 mt-1">{errors.name}</p>
                    )}
                </div>

                {/* Email Field */}
                <div className="mb-4">
                    <label className="block text-gray-700 mb-2" htmlFor="email">
                        Your Email:
                    </label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg"
                        placeholder="Enter your email"
                    />
                    {errors.email && (
                        <p className="text-red-600 mt-1">{errors.email}</p>
                    )}
                </div>

                {/* Contact Number Field */}
                <div className="mb-4">
                    <label className="block text-gray-700 mb-2" htmlFor="contact">
                        Your Contact Number:
                    </label>
                    <input
                        type="text"
                        name="contact"
                        value={formData.contact}
                        onChange={handleInputChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg"
                        maxLength="10" // Restrict the input to 10 digits
                        placeholder="Enter your contact number"
                    />
                    {errors.contact && (
                        <p className="text-red-600 mt-1">{errors.contact}</p>
                    )}
                </div>

                {/* Inquiry Field */}
                <div className="mb-4">
                    <label className="block text-gray-700 mb-2" htmlFor="inquiry">
                        Your Inquiry:
                    </label>
                    <textarea
                        name="inquiry"
                        value={formData.inquiry}
                        onChange={handleInputChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg"
                        rows="4"
                        placeholder="Enter your inquiry"
                    />
                </div>

                <button
                    type="submit"
                    className="bg-green-600 text-white px-6 py-2 rounded-full hover:bg-green-700 transition duration-300"
                >
                    Submit Inquiry
                </button>
            </form>
        </div>
    );
};

export default InquiryForm;
