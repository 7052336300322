import React from 'react';

const TermsAndConditions = () => {
    return (
        <div>
            <div className="relative bg-cover bg-center bg-no-repeat h-[300px] flex items-center text-left bg-[url('https://Nirogi Dhara.com/wp-content/themes/twenty-twenty-one-child/assets/images/about-bg.jpg')] transition-opacity duration-1000 transform "><div className=" pl-36 z-10 rounded-lg opacity-100"><h1 className="text-3xl md:text-4xl font-bold tracking-wide text-green-800 mb-4">TERMS AND CONDITION</h1>
            </div>
            </div>
            <div className="min-h-screen ">
                <div className="max-w-7xl mx-auto bg-white p-8 ">
                    {/* Main Content */}
                    <section className="mb-8">

                        <h2 className="text-xl font-semibold text-gray-600 mt-6 mb-3">Welcome to the Nirogi Dhara!</h2>
                        <p className="text-gray-500 mb-4">
                            Nirogi Dhara provides its audience with the content, services, and products available on this website subject to the certain Terms & Conditions. Our Privacy Policy, Payment Policy, and other T&C, all of which you can find on our website to understand how our services and products are positioned. By accessing or using this website, you are acknowledging that you have read, understood, and you agree, without limitation or qualification, to be bound by these Terms and Conditions in this Agreement, so please read this carefully before proceeding.
                        </p>
                    </section>

                    <section className="mb-8">
                        <h3 className="text-xl font-semibold text-gray-600 mb-2">Privacy</h3>
                        <p className="text-gray-500 mb-4">Please review our privacy-policy so that you may understand our privacy practices.</p>
                    </section>

                    <section className="mb-8">
                        <h3 className="text-xl font-semibold text-gray-600 mb-2">Payment Policy</h3>
                        <p className="text-gray-500 mb-4">
                            Please see our Payment Policy to understand the purchase processes of our products. All international shipping orders may attract local duties applicable in that country and accordingly the customer will have to pay such local duties/taxes.
                        </p>
                    </section>

                    {/* <section className="mb-8">
                        <h3 className="text-xl font-semibold text-gray-600 mb-2">Shipping & Delivery</h3>
                        <p className="text-gray-500 mb-4">
                            Due to the prevailing COVID-19 lockdown and restrictions on movement, we regret to inform you that there is a certain delay in the delivery of products. Sorry for the inconvenience.
                        </p>
                    </section> */}

                    <section className="mb-8">
                        <h3 className="text-xl font-semibold text-gray-600 mb-2">Products And Services For Personal Use</h3>
                        <p className="text-gray-500 mb-4">
                            The products and services described on this website, and any samples thereof we may provide to you, are for personal use only. You may not sell or resell any of the products or services, or samples thereof, you receive from us. We reserve the right, with or without notice, to cancel or reduce the quantity of any products or services to be provided to you that we believe, in our sole discretion, may result in the violation of our Terms and Conditions.
                        </p>
                    </section>

                    <section className="mb-8">
                        <h3 className="text-xl font-semibold text-gray-600 mb-2">Accuracy of Information</h3>
                        <p className="text-gray-500 mb-4">
                            We attempt to be as accurate as possible when describing our products on the website. However, except to the extent implied by applicable law, we do not warrant that the product descriptions, colours, information or other content available on the website are accurate, complete, reliable, current, or error-free.
                        </p>
                    </section>

                    <section className="mb-8">
                        <h3 className="text-xl font-semibold text-gray-600 mb-2">Intellectual Property</h3>
                        <p className="text-gray-500 mb-4">
                            All information and products available on the website and its “look and feel”, including but not limited to trademarks, logos, service marks, text, graphics, logos, button icons, images, audio clips, data compilations and software, and the compilation and organization thereof (collectively, the “Content”) is the property of Nirogi Dhara, our Affiliates, partners or licensors, and is protected by laws of India, including laws governing all applicable forms of intellectual property. Except as set forth in the limited licenses in Section 6, or as required under applicable law, neither the Content nor any portion of this website may be used, reproduced, duplicated, copied, sold, resold, accessed, modified, or otherwise exploited, in whole or in part, for any purpose without our express, prior written consent.
                        </p>
                    </section>

                    <section className="mb-8">
                        <h3 className="text-xl font-semibold text-gray-600 mb-2">Special Features, Functionality And Events</h3>
                        <p className="text-gray-500 mb-4">
                            Nirogi Dhara may offer certain special features and functionality or events (such as contests, promotions or other offerings) which may (a) be subject to terms of use, rules and/or policies in addition to or in lieu of these Terms and Conditions; and (b) be offered by us or by third parties. If so, we will notify you of this and if you choose to take advantage of these offerings, you agree that your use of those offerings will be subject to such additional or separate terms of use, rules and/or policies.
                        </p>
                    </section>

                    <section className="mb-8">
                        <h3 className="text-xl font-semibold text-gray-600 mb-2">Submissions</h3>
                        <p className="text-gray-500 mb-4">
                            It is our policy to decline unsolicited suggestions and ideas. Notwithstanding our policy with regard to unsolicited suggestions and ideas, any inquiries, feedback, suggestions, ideas or other information you provide us (collectively, “Submissions”) will be treated as non-proprietary and non-confidential. Subject to the terms of our Privacy Policy, by transmitting or posting any Submission, you hereby grant us the right to copy, use, reproduce, modify, adapt, translate, publish, license, distribute, sell or assign the Submission in any way as we see fit, including but not limited to copying in whole or in part, creating derivative works from, distributing and displaying any Submission in any form, media, or technology, whether now known or hereafter developed, alone or as part of other works, or using the Submission within or in connection with our products or services. You also acknowledge that your Submission will not be returned and we may use your Submission, and any ideas, concepts or know how contained therein, without payment of money or any other form of consideration, for any purpose including, without limitation, developing, manufacturing, distributing and marketing products. If you make a Submission, you represent and warrant that you own or otherwise control the rights to your Submission. You further represent and warrant that such Submission does not constitute or contain software viruses, commercial solicitation, chain letters, mass mailings, or any form of “spam”. You may not use a false email address, impersonate any person or entity, or otherwise mislead us as to the origin of any Submission. You agree to indemnify us for all claims arising from or in connection with any claims to any rights in any Submission or any damages arising from any Submission
                        </p>
                    </section>

                    <section className="mb-8">
                        <h3 className="text-xl font-semibold text-gray-600 mb-2">Copyright Complaints</h3>
                        <p className="text-gray-500 mb-4">
                            We respect the intellectual property of others. If you believe that copyrighted materials have been copied in a way that constitutes copyright infringement, please send an email or written notice to us for notices of infringement and provide the following: (i) identification of the copyrighted work(s) that you claim has been infringed and that you are the copyright owner or authorized to act on the copyright owner’s behalf; (ii) a description of the material that you claim is infringing and the location of the material on the website; (iii) your address, telephone number and email address to: care@nirogidhara.com Note: The above contact information is provided exclusively for notifying Nirogi Dhara that copyrighted material may have been infringed. All other inquiries will not receive a response through this process and should be directed to our customer service group by email at: care@nirogidhara.com
                        </p>
                    </section>

                    <section className="mb-8">
                        <h3 className="text-xl font-semibold text-gray-600 mb-2">Indemnification</h3>
                        <p className="text-gray-500 mb-4">
                            You agree to defend, indemnify and hold us harmless for any loss, damages or costs, including reasonable attorneys’ fees, resulting from any third party claim, action, or demand resulting from your use of Nirogi Dhara or breach of these Terms and Conditions. You also agree to indemnify us for any loss, damages, or costs, including reasonable attorneys’ fees, resulting from your use of software robots, spiders, crawlers, or similar data gathering and extraction tools, or any other action you take that imposes an unreasonable burden or load on our infrastructure.
                        </p>
                    </section>

                    <section className="mb-8">
                        <h3 className="text-xl font-semibold text-gray-600 mb-2">General</h3>
                        <p className="text-gray-500 mb-4">
                            You acknowledge and agree that these Terms and Conditions constitute the complete and exclusive agreement between us concerning your use of the website, and supersede and govern all prior proposals, agreements, or other communications. We reserve the right, in our sole discretion, to change these Terms and Conditions at any time by posting the changes on the website and providing notice of such change. Any changes are effective immediately upon posting to the Site and release of notice of such change. Your continued use of the website thereafter constitutes your agreement to all such changed Terms and Conditions. We may, with or without prior notice, terminate any of the rights granted by these Terms and Conditions. You shall comply immediately with any termination or other notice, including, as applicable, by ceasing all use of the website. Nothing contained in these Terms and Conditions shall be construed as creating any agency, partnership, or other form of joint enterprise between us. Our failure to require your performance of any provision hereof shall not affect our full right to require such performance at any time thereafter, nor shall our waiver of a breach of any provision hereof be taken or held to be a waiver of the provision itself. In the event that any provision of these Terms and Conditions shall be unenforceable or invalid under any applicable law or be so held by any applicable arbitral award or court decision, such unenforceability or invalidity shall not render these Terms and Conditions unenforceable or invalid as a whole but these Terms and Conditions shall be modified, to the extent possible, by the adjudicating entity to most fully reflect the original intent of the parties as reflected in the original provision.
                        </p>
                    </section>

                   
                    <section className="mb-8">
                        <h3 className="text-xl font-semibold text-gray-600 mb-2">Terms of data collection and usage:-</h3>
                        <p className="text-gray-500 mb-4">
                            We at Nirogi Dhara Private Limited do not share any data with external vendors for any purpose

                            By calling us at numbers displayed on screen of the Program a person gives us right to register themselves in our data base and provide permission to call/message them for regular updates. This also goes for numbers registered as DND with TRAI

                            To opt-out from regular updates please send us an email (care@nirogidhara.com) with the phone number/email address that you want us to remove from our data base and get unregister for future updates.
                        </p>
                    </section>

                    <section className="mb-8">
                        <p className=" text-gray-500 mb-2"><b className='text-xl font-semibold text-gray-600 '>Disclaimer: </b>
                        Please read and edit the Refund & Cancellation Policy given below as per your /website’s requirement. Don’t use or apply these as is basis on your website.
                        </p>
                    </section>

                    <section className=''>
                        <p className='text-gray-500 mb-1 italic'>If you have any questions regarding these Terms and Conditions, please email us at: care@nirogidhara.com</p>
                    </section>


                    {/* Other sections continue... */}

                    
                </div>
            </div>

        </div>
    );
};

export default TermsAndConditions;
