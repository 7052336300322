import React, { useEffect, useRef, useState } from 'react';

export default function ServiceSteps() {
  const [isVisibleLeft, setIsVisibleLeft] = useState(false);
  const [isVisibleRight, setIsVisibleRight] = useState(false);
  const leftRef = useRef(null);
  const rightRef = useRef(null);

  useEffect(() => {
    const observerOptions = {
      threshold: 0.1, // Trigger when 10% of the element is visible
    };

    const leftObserver = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setIsVisibleLeft(true);
      }
    }, observerOptions);

    const rightObserver = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setIsVisibleRight(true);
      }
    }, observerOptions);

    if (leftRef.current) {
      leftObserver.observe(leftRef.current);
    }

    if (rightRef.current) {
      rightObserver.observe(rightRef.current);
    }

    return () => {
      if (leftRef.current) leftObserver.unobserve(leftRef.current);
      if (rightRef.current) rightObserver.unobserve(rightRef.current);
    };
  }, []);

  return (
    <div className="flex justify-center items-center py-16 bg-gray-50 px-6">
      <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-16">

        {/* Left Column - Text Content with fadeInLeft Animation */}
        <div
          ref={leftRef}
          className={`space-y-6 transform transition-opacity duration-1000 ${isVisibleLeft ? 'opacity-100 animate-fadeInLeft' : 'opacity-0'
            }`}
        >
          <h5 className="text-lg text-green-600 font-medium italic">How it works</h5>
          <h2 className="text-3xl md:text-5xl font-bold text-gray-800 leading-tight">
            Easy steps to order the medicine
          </h2>
          <p className="mt-4 text-sm md:text-base text-gray-600">
            Ordering our medicine is simple and hassle-free. Just browse through our offerings, select the medicine that suits your needs, and add it to your cart. Proceed to checkout, and we'll handle the rest.
          </p>
          <p className="mt-4 text-sm md:text-base text-gray-600">
            Once your order is placed, you'll receive a confirmation with all the details. Our team will promptly schedule and deliver the service at your convenience, ensuring a smooth and seamless experience.
          </p>

          {/* Contact Info */}
          <div className="mt-8 flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
            <div className="flex items-center space-x-2 bg-white p-4 shadow-md rounded-lg transition-transform hover:scale-105">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8 text-green-600"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M3 8l7 7 10-10" />
              </svg>
              <div>
                <div className="text-sm text-gray-500">Need help? Call us</div>
                <div className="text-xl font-semibold text-gray-800"><a href="tel:+918094187941" className="hover:underline">80941-87941</a></div>
              </div>
            </div>
            <div className="flex items-center space-x-2 bg-white p-4 shadow-md rounded-lg transition-transform hover:scale-105">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8 text-green-600"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M5.5 10.5L9 13.5l5-5" />
              </svg>
              <div>
                <div className="text-sm text-gray-500">Need help? Email us</div>
                <div className="text-xl font-semibold text-gray-800">nirogidhara@gmail.com</div>
              </div>
            </div>
          </div>
        </div>

        {/* Right Column - Steps with fadeInRight Animation */}
        <div
          ref={rightRef}
          className={`space-y-8 transform transition-opacity duration-1000 ${isVisibleRight ? 'opacity-100 animate-fadeInRight' : 'opacity-0'
            }`}
        >
          <div className="bg-green-100 p-6 rounded-lg transition-all duration-300 hover:shadow-lg">
            <h3 className="text-lg md:text-xl font-semibold text-gray-800 flex items-center space-x-2">
              <span className="bg-green-600 text-white rounded-full h-10 w-10 flex items-center justify-center">1</span>
              <span>Consultations with our expert</span>
            </h3>
            <p className="mt-2 text-sm md:text-base text-gray-600">
              If you're referring to wellness, Ayurvedic practices, or health consultations, I can help provide information or tips on that. Could you clarify what specific areas you’re interested in?
            </p>
          </div>

          <div className="bg-white border p-6 rounded-lg transition-all duration-300 hover:shadow-lg">
            <h3 className="text-lg md:text-xl font-semibold text-gray-800 flex items-center space-x-2">
              <span className="bg-green-600 text-white rounded-full h-10 w-10 flex items-center justify-center">2</span>
              <span>Get an appointment</span>
            </h3>
            <p className="mt-2 text-sm md:text-base text-gray-600">
              If you’re looking to schedule a consultation related to Nirogi Dhara, you might want to check their official website or contact them via phone or email. They often have an online booking system or a contact form. If you need help finding specific details, let me know!
            </p>
          </div>

          <div className="bg-white border p-6 rounded-lg transition-all duration-300 hover:shadow-lg">
            <h3 className="text-lg md:text-xl font-semibold text-gray-800 flex items-center space-x-2">
              <span className="bg-green-600 text-white rounded-full h-10 w-10 flex items-center justify-center">3</span>
              <span>Enjoy your service</span>
            </h3>
            <p className="mt-2 text-sm md:text-base text-gray-600">
              Thank you! I'm glad to help. If you have any questions or need assistance with anything else, feel free to ask. Enjoy your day!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
