import React, { useState, useEffect } from 'react';
import NewsletterSection from '../Newsletter';
import config from '../../config';

const Blog = () => {  
    const [blogs, setBlogs] = useState([]);

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await fetch(`${config.API_BASE_URL}/fetchblogs`);
                if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
                const data = await response.json();
                setBlogs(data);
                console.log("Fetched blogs:", data);
            } catch (error) {
                console.error('Error fetching blogs:', error);
            }
        };

        fetchBlogs();
    }, []);

    return (
        <div className="min-h-screen flex flex-col">
            <section className="text-center my-12">
                <h1 className="text-5xl tracking-wide font-medium">Blog</h1>
                <p className="text-xl mt-4 pr-4 tracking-wide text-gray-700">Archives</p>
            </section>

            {/* Blog Grid Section */}
            <section className="mt-4 grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 px-20 animate-fadeIn">
                {blogs.map((blog, index) => (
                    <div key={index} className="mb-8">
                        {blog.images && blog.images.length > 0 ? (
                                <img
                                    src={`${config.API_BASE_URL}/blogimages/${JSON.parse(blog.images)[0]}`}
                                    alt="Product Image 0"
                                    className="w-full h-44 rounded-lg object-cover"
                                />
                            ) : (
                                <p>No image available</p>
                            )}
                        <div className="p-2">
                            <h2 className="text-xl font-bold hover:text-green-500">{blog.blogheading}</h2>
                           
                            <p className="mt-2 text-gray-700">
                                {blog.blogcontent || 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.'}
                            </p>
                        </div>
                    </div>
                ))}
            </section>

            <NewsletterSection/>   
        </div>
    );
};

export default Blog;
