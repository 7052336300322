import React from 'react';
import TestimonialsVideos from './Header/TestimonialsVideos';

const TestimonialsSection = () => {
  const testimonials = [
    // Add testimonial data here
    {
      text: 'I was amazed by the natural remedies and how effective they were. It’s great to find a solution that’s both holistic and reliable.',
      author: 'John Doe'
    },
    {
      text: 'The service was exceptional, and the personalized care made all the difference. I feel rejuvenated and healthier than ever!',
      author: 'Jane Doe'
    },
    {
      text: 'Absolutely transformative experience! The team genuinely cares about your well-being, and their products have changed my life for the better.',
      author: 'Bob Smith'
    }
  ];

  return (
    <section className="py-16 px-16 mt-10 bg-white">
      <h6 className='text-green-600 mx-auto font-medium text-center italic'>Testimonial</h6>
      <div className="container mx-auto text-center bg-[url('https://fullkit.moxcreative.com/bagasaka/wp-content/uploads/sites/9/2022/07/2.png')] bg-contain bg-no-repeat bg-center bg-white brightness-110">

      <TestimonialsVideos></TestimonialsVideos>
       
      </div>
    </section>
  );
};

export default TestimonialsSection;
